import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Related components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Tables</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Checkboxes are used for a list of options where the user may select multiple options, including all or none.`}</p>
    <h4 {...{
      "id": "checkbox-states"
    }}>{`Checkbox states`}</h4>
    <p>{`The checkbox control allows for three states: `}<strong parentName="p">{`selected`}</strong>{`, `}<strong parentName="p">{`unselected`}</strong>{`, and `}<strong parentName="p">{`indeterminate`}</strong>{`. The indeterminate state comes into play when the checkbox contains a sublist of selections, some of which are selected, and some unselected.`}</p>
    <h4 {...{
      "id": "click-target"
    }}>{`Click target`}</h4>
    <p>{`Users should be able to select the checkbox by clicking on the box directly or by clicking on its label.`}</p>
    <h4 {...{
      "id": "default-selection"
    }}>{`Default selection`}</h4>
    <p>{`The default view of a set of checkboxes is having no option selected.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAAgUlEQVQoz2NgwAMyMzNR+JaWlmBaXFycgWTQ2toKpidOnMi4evVquLiJiQkDWcDNzQ1Mu7q68rq7u4vY29tzg/gdHR3kuVBMTAxGC0lISIgAsTDQIFayvQzTBKT5gBhkmAgQc1LDQAFxCBCBuRzoWooMZAZiJigmz3XohmKxBKceAJSVFU1ZtVRKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The default state for checkboxes is unchecked.",
            "title": "The default state for checkboxes is unchecked.",
            "src": "/static/06579fa37df5ce59fdebc002dc3f0932/fb070/checkbox-usage-1.png",
            "srcSet": ["/static/06579fa37df5ce59fdebc002dc3f0932/d6747/checkbox-usage-1.png 288w", "/static/06579fa37df5ce59fdebc002dc3f0932/09548/checkbox-usage-1.png 576w", "/static/06579fa37df5ce59fdebc002dc3f0932/fb070/checkbox-usage-1.png 1152w", "/static/06579fa37df5ce59fdebc002dc3f0932/c3e47/checkbox-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Caption mdxType="Caption">By default, checkboxes are unchecked.</Caption>
    <h2 {...{
      "id": "related-components"
    }}>{`Related components`}</h2>
    <h4 {...{
      "id": "checkbox-vs-radio-button"
    }}>{`Checkbox vs. radio button`}</h4>
    <p>{`Whereas radio buttons represent a group of `}<em parentName="p">{`mutually exclusive`}</em>{` choices, users can select one or more checkboxes from a group. In use cases where only one selection of a group is allowed, use the radio button component instead of the checkbox.`}</p>
    <h4 {...{
      "id": "checkbox-vs-toggle-switch"
    }}>{`Checkbox vs. toggle switch`}</h4>
    <p>{`Generally, toggle switches are preferred when the resulting action will be instantaneously applied, without the need for further confirmation. Checkboxes generally represent one input in a larger flow which requires a final confirmation step.`}</p>
    <h4 {...{
      "id": "references"
    }}>{`References`}</h4>
    <br />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/toggle"
        }}>{`Toggle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-button"
        }}>{`Radio button`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/form"
        }}>{`Form`}</a></li>
      <li parentName="ul"><em parentName="li">{`Data table (coming soon)`}</em></li>
    </ul>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h4 {...{
      "id": "headings"
    }}>{`Headings`}</h4>
    <p>{`If necessary, a heading can accompany a set of checkboxes to provide further context or clarity. Use sentence case for checkbox headings. In the example below, “IBM designers” would be the heading for the set of checkboxes.`}</p>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Always use clear and concise labels for checkboxes. Be explicit about the results that will follow if the checkbox is selected. Labels appear to the right of checkboxes.`}</p>
    <h2 {...{
      "id": "tables"
    }}>{`Tables`}</h2>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/components/data-table/usage"
      }}>{`data table`}</a>{` section for guidance on how to use checkboxes within a table.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      